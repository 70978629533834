// Function as event when DOM is loaded to trigger transitions.
// it will set opacity to 0 and then change it back after 500 ms in order to trigger
// the transition of any element with the class name: "transitionable".
document.addEventListener("DOMContentLoaded", function() {
    var elementsTransitionable = document.getElementsByClassName("transitionable");
    Array.from(elementsTransitionable).forEach((element) => {
        element.style.opacity = 0;
    } );
    setTimeout(() => {
    Array.from(elementsTransitionable).forEach((element) => {
        element.style.opacity = 1;
    } );
    }, 500);
    
    console.log("DOM loaded - executing transitions in 500ms.");
});