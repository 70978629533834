import React, { Component } from 'react';
import NavMenu from './NavMenu';
import './Layout.css';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className='main-background'>
        
        <NavMenu />
        <div tag="main">
          {this.props.children}
        </div>
      </div>
    );
  }
}
