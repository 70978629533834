import React, { useState } from 'react';
import './NavMenu.css';
import fib_logotype from '../../Media/FIB_log.png';

export default function NavMenu(props) {
  const [isActive, setIsActive] = useState(false);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  }

  const removeActive = () => {
    setIsActive(false);
  }

  return (
    <header>
      <div className='w-100'>
        <div className='font-josefin-light navbar-style box-shadow mb-3'>
        <a href='/programas' className='navbar-link-custom'>
          <img src={fib_logotype} className='navbar-logotype'></img>
        </a>
          <ul className={"navbar-menu " + (isActive ? "active" : "")}>
            <li onClick={removeActive}>
              <a href='/progs' className='navbar-link-custom'>PROGRAMAS</a>
            </li>
            <li onClick={removeActive}>
              <a href='/blogs' className='navbar-link-custom'>BLOGS</a>
            </li>
            <li onClick={removeActive}>
              <a href='/' className='navbar-link-custom navbar-brand-style'>FIBSTUDIO</a>
            </li>
            <li onClick={removeActive}>
              <a href='/tienda' className='navbar-link-custom'>TIENDA</a>
            </li>
            <li onClick={removeActive}>
              <a href='/login' className='navbar-link-custom'>INICIA SESIÓN / REGISTRATE</a>
            </li>
          </ul>

          <div className={"hamburguer " + (isActive ? "active" : "")} onClick={toggleActiveClass}>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
          </div>

        </div>

      </div>
    </header>
  );
}
