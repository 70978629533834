import React, { Component } from "react";

import './Home.css';
import '../Common/c_actions.js';
import '../Components/Pages/Home/Home_Programs.js';
import '../Components/Pages/Home/Home_Teachers.js';
import '../Components/Pages/Home/Home_Reviews.js';
import '../Components/Pages/Home/Home_Plans.js';

import gif_background from '../Media/Home_Background.gif';
import Home_Programs from "../Components/Pages/Home/Home_Programs.js";
import Home_Teachers from "../Components/Pages/Home/Home_Teachers.js";
import Home_Reviews from "../Components/Pages/Home/Home_Reviews.js";
import Home_Plans from "../Components/Pages/Home/Home_Plans.js";
import Home_Contacts from "../Components/Pages/Home/Home_Contact.js";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <div className="video-container" >

          <img className="background-player" src={gif_background}>
          </img>
          <div className="video-foreground-content transitionable" >

            <div className="video-content-body font-josefin-italic">
              <ul>
                <li>Fitness</li>
                <li>Inspiración</li>
                <li>Bienestar</li>
              </ul>

            </div>

            <div className="video-content-footer">
              <button className="btn-general font-josefin">
                <label><b>¡QUIERO UNIRME!</b></label>
              </button>
            </div>

          </div>

        </div>

        {/* Page 2 */}
        <Home_Teachers />

        {/* Page 3 */}
        <Home_Programs />

        {/* Page 4 */}
        <Home_Reviews />

        {/* Page 5 */}
        <Home_Plans />

        {/* Page 6 */}
        <Home_Contacts />

      </div>
    );
  }
}
