import { Home } from "./Pages/Home";
import { Programs } from "./Pages/Programs";

const AppRoutes = [
  {
    index: true,
    path: "/",
    element: <Home />
  },
  {
    index: true,
    path: "/progs",
    element: <Programs/>
  }
];

export default AppRoutes;
