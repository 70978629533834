import './Programs_Main.css';

import sliderIcon from '../../../Media/slider.png';
import { Card, CardHeader } from 'reactstrap';
import { useState } from 'react';

export default function Programs_Main(props) {

    const [imageIndex, setImageIndex] = useState(0);

    const authenticated = props.authenticated === undefined ? false : props.authenticated;
    const videoURL = "https://customer-f33zs165nr7gyfy4.cloudflarestream.com/6b9e68b07dfee8cc2d116e4c51d6a957/iframe?poster=https%3A%2F%2Fcustomer-f33zs165nr7gyfy4.cloudflarestream.com%2F6b9e68b07dfee8cc2d116e4c51d6a957%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600";
    const programTitle = "1. Programa A testing tested";
    const programSubTitle = "Tags yoga";
    const programDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci erat, accumsan vel volutpat ut, scelerisque in nisi. Donec sed eleifend magna. Quisque efficitur placerat nisl, quis efficitur est consectetur at. Suspendisse porttitor dignissim orci, a eleifend magna vestibulum at. Suspendisse eu ipsum vehicula, fringilla lectus sed, aliquam lectus. Praesent. ";
    const programVideosThumbnails = [
        "https://images.freeimages.com/images/large-previews/7bc/yoga-1240391.jpg"
        , "https://media.istockphoto.com/id/923293066/es/foto/joven-atleta-hermoso-es-posando-en-el-estudio.jpg?s=1024x1024&w=is&k=20&c=g0p3je4d3Lmb4o5J30Q2XMl3J66al4jwXo0-VoJ3ybc="
        , "https://media.istockphoto.com/id/1503227927/es/foto/mujer-balanceando-kettlebell-en-el-gimnasio.jpg?s=1024x1024&w=is&k=20&c=VhrPFdnHtgPNa9CX1U2q7Uz0OhvOsx_MUQ1-7-cwgyo="
        , "https://media.istockphoto.com/id/1503228009/es/foto/mujer-balanceando-kettlebell-en-el-gimnasio.jpg?s=1024x1024&w=is&k=20&c=22TA1aTg07J00mxTODPgTeKmofIgVGzTHsWrD9JQuxo="
        , "https://media.istockphoto.com/id/1469664093/es/foto/mujer-usando-rodillo-de-espuma-para-automasajear-los-m%C3%BAsculos-en-el-gimnasio.jpg?s=1024x1024&w=is&k=20&c=JujvhG2qbSVRXEpi-DatCh1UQKixyYNJZxMsoE6SJt4="

    ];

    let leftSliderRender = <></>;
    let rightSliderRender = <></>;
    if (programVideosThumbnails.length >= 4) {
        leftSliderRender = <div className='img_thumbnails_carrousel_slider' onClick={event => sliderEvent(true)}>
            <div className='slider-sm'>
                <img className='img-slider-style' src={sliderIcon}></img>
            </div>
        </div>
        rightSliderRender = <div className='img_thumbnails_carrousel_slider' onClick={event => sliderEvent(false)}>
            <div className='slider-sm'>
                <img className='img-slider-style r180' src={sliderIcon} ></img>
            </div>
        </div>;
    }

    const sliderEvent = (goLeft) => {
        if (goLeft) {
            console.log("slider left event");
            if (imageIndex != 0){
                setImageIndex(imageIndex-1);
            }

        } else {
            console.log("slider right event");
            if (imageIndex < (programVideosThumbnails.length-3)){
                setImageIndex(imageIndex+1);
            }
        }
    }

    return (
        <>
            <div className='container w_M_100 pl_10_px pr_10_px mr_10_px ml_10_px pt_30_px pb_30_px'>
                <div className='row'>
                    {/* header */}
                    <div className='col-12 header'>
                        <h2 className='ptitle font-josefin'>
                            {programTitle}
                        </h2>
                        <sub>
                            <h4 className='ptags font-josefin'>
                                {programSubTitle}
                            </h4>
                        </sub>
                    </div>

                    {/* video */}
                    <div className='col-6 h_vh_35'>
                        <iframe
                            src={videoURL}
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                            className='video-player'
                        ></iframe>
                    </div>

                    {/* content */}
                    <div className='col-6 h_vh_35'>

                        {/* thumbnails */}
                        <div className='row h_40 pt_5_px pb_10_px'>
                            <div className='col-1'>
                                {leftSliderRender}
                            </div>

                            {programVideosThumbnails.slice(imageIndex,imageIndex+3).map((thumbnail, i) => {
                                if (i < 3) {
                                    return (
                                        <div className='col-3' key={i} >
                                            <img src={thumbnail} className='img_thumbnail_2' />

                                            {/* <div className='img_thumbnail' style={{ backgroundImage: `url(${thumbnail})` }}>
                                            </div> */}
                                        </div>
                                    );
                                }

                            })}

                            <div className='col-1'>
                                {rightSliderRender}
                            </div>
                        </div>

                        {/* description */}
                        <div className='row h_60'>
                            <div className='col-12'>
                                <p className='font-josefin'>
                                    {programDescription}
                                </p>
                            </div>
                        </div>

                        {/* button */}
                        <div className='row h_10'>
                            <div className='col-12'>
                                
                                <button className='btn-empezar font-josefin'>Empezar</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}