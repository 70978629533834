import React, { Component } from "react";

import './Programs.css';
import Home_Contact from "../Components/Pages/Home/Home_Contact";
import Programs_Main from "../Components/Pages/Programs/Programs_Main";

export class Programs extends Component {
    static displayName = Programs.name;
    static authenticated = false;

    render() {
        return (
            <div className="main-container">
                <Programs_Main authenticated={this.authenticated} />

                <Programs_Main authenticated={this.authenticated} />

                <Programs_Main authenticated={this.authenticated} />


                {/* Footer */}
                <Home_Contact />
            </div>
        );
    }
}