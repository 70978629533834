import './Home_Contact.css';
import instagramIcon from '../../../Media/instagram.png';
import telegramIcon from '../../../Media/telegram.png';
import whatsappIcon from '../../../Media/whatsapp.png';

export default function Home_Contact(props) {

    return (
        <div className='contact-container'>
            <div className='contact-title font-mafisha'>
                Únete a nuestra comunidad
            </div>

            <ul className='contact-icons'>
                <li>
                    <a href='https://www.instagram.com/thefibstudio?igsh=enU0NmFjMzd3dGts'>
                        <img className='icon-style' src={instagramIcon} />
                    </a>
                </li>
                <li>
                    <a href='http://telegram.com'>
                        <img className='icon-style' src={telegramIcon} />
                    </a>
                </li>
                <li>
                    <a href='https://api.whatsapp.com/message/SQHC7UMSEG4HF1?autoload=1&app_absent=0'>
                        <img className='icon-style' src={whatsappIcon} />
                    </a>
                </li>
            </ul>

            <div className='subscribe-form'>
                
                    <label>Sé el primero en recibir información sobre nuestros programas, eventos, etc.</label>
                
                    <input className='email-control' type='text' placeholder='Email' ></input>
                
            </div>

        </div >
    );
}