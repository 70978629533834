import './Home_Teachers.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from "react-responsive-carousel";
import imageTeacher_1 from '../../../Media/perfil.jpg';
import imageTeacher_2 from '../../../Media/perfil_2.jpg';
import imageTeacher_3 from '../../../Media/perfil_3.jpg';
import instagramIcon from '../../../Media/instagram.png';
import facebookIcon from '../../../Media/facebook.png';
import xIcon from '../../../Media/x.png';
import threadsIcon from '../../../Media/threads.png';
import sliderIcon from '../../../Media/slider.png';

import { useEffect, useRef, useState } from 'react';

export default function Home_Teachers(props) {
    const [activeTeacher, setActiveTeacher] = useState(null);
    const [teachers, setTeachers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const teacherInformation = useRef();
    const teacherImageLarge = useRef();

    const teachersURL = "https://fibstudio-d1-dev.carlos-brugnoni.workers.dev/api/teachers";//process.env.REACT_APP_HOME_TEACHERS;

    const changeSelectedTeacher = (tab) => {

        // there is no programs loaded.
        if (teachers === undefined || teachers === null || teachers.length == 0) {
            setActiveTeacher(null);
            return;
        }

        if (tab < 0) {
            setActiveTeacher(teachers[0]);
            return;
        }

        if (activeTeacher.Id == tab) {
            return;
        }

        teacherImageLarge.current.animate({
            opacity: [1, 0]
        }, 50);

        teacherInformation.current.animate(
            {
                opacity: [1, 0]
            }, 50
        );

        //set teacher
        let tabIndex = teachers.findIndex(tc => tc.Id == tab);
        let selectedTeacher = teachers[tabIndex];
        setActiveTeacher(selectedTeacher);
        //organize teachers array
        let firstHalf = teachers.slice(0, tabIndex);
        let secondHalf = teachers.slice(tabIndex);
        setTeachers([...secondHalf, ...firstHalf]);

        teacherInformation.current.animate(
            {
                opacity: [0, 1]
            }, 1500
        );

        teacherImageLarge.current.animate({
            opacity: [0, 1]
        }, 1500);
    }

    const sliderEvent = (goLeft) => {
        if (goLeft) {
            console.log("slider left event");
            //set the previous card as active.
            //setActiveTeacher(teachers[teachers.findIndex(tc => tc.id == activeTeacher.id) - 1]);
            //set the last card as the first one.
            // let lastTeacher = teachers[teachers.length - 1];
            // let filteredTeachers = teachers.filter(s => s.id !== activeTeacher.id);
            // let finalList = [activeTeacher, ...filteredTeachers];
            // setActiveTeacher(lastTeacher);
            // setTeachers(finalList);

            let activeTeacherIndex = teachers.findIndex(tc => tc.Id == activeTeacher.Id);
            console.log(activeTeacherIndex);
            if (activeTeacherIndex == 0) {
                changeSelectedTeacher(teachers[teachers.length - 1].Id);
            } else {
                changeSelectedTeacher(teachers[activeTeacherIndex - 1].Id);
            }

        } else {
            console.log("slider right event");
            //set the next card as active.
            //setActiveTeacher(teachers[teachers.findIndex(tc => tc.id == activeTeacher.id) + 1]);
            //set the first card as the last one.
            // let firstTeacher = teachers[0];
            // let filteredTeachers = teachers.filter(s => s.id !== activeTeacher.id);
            // let finalList = [...filteredTeachers, activeTeacher];
            // setActiveTeacher(firstTeacher);
            // setTeachers(finalList);

            let activeTeacherIndex = teachers.findIndex(tc => tc.Id == activeTeacher.Id);
            console.log(activeTeacherIndex);
            if (activeTeacherIndex == teachers.length - 1) {
                changeSelectedTeacher(teachers[0].Id);
            } else {
                changeSelectedTeacher(teachers[activeTeacherIndex + 1].Id);
            }
        }
    }

    const fetchTeachers = async () => {
        try {
            console.log("fetching from : ", teachersURL);
            const response = await fetch(teachersURL,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
            console.log("response from fetching teachers: ",response);
            const responseWrapper = await response.json();
            console.log("response as json: ",responseWrapper);

            if (responseWrapper.success){
                setTeachers(responseWrapper.data);
                setLoading(false);
                setActiveTeacher(responseWrapper.data[0]);
                setError(false);
            }else{
                setTeachers([]);
                setLoading(false);
                setError(true);
            }
            // switch (responseWrapper.susccess) {
            //     case 0:
            //         setTeachers(responseWrapper.data);
            //         setLoading(false);
            //         setActiveTeacher(responseWrapper.data[0]);
            //         console.log("teacher from fetch: ", responseWrapper.data[0]);
            //         console.log("selected Teacher: ", activeTeacher);
            //         break;

            //     case 2:
            //         setTeachers([]);
            //         setLoading(false);
            //         break;
            // }

        } catch (err) {
            console.error(err);
            setTeachers([]);
            setLoading(true);
            setError(true);
        }
    };

    //use effect to fetch teachers values.
    useEffect(() => {
        console.log("use effect on teachers triggered.");
        if ((teachers === undefined || teachers === null || teachers.length == 0) && !error) {
            fetchTeachers();
        }
    }, []);

    if (loading || activeTeacher === null) {
        return (
            <>
                <div className='container'>
                    <div className='text-center'>
                        <h2>Loading</h2>
                    </div>
                </div>
            </>
        );

    } else {
        let socialIcon = "";
        switch (activeTeacher.socialType) {
            case 0:
                socialIcon = instagramIcon;
                break;

            case 1:
                socialIcon = facebookIcon;
                break;

            case 2:
                socialIcon = xIcon;
                break;

            case 3:
                socialIcon = threadsIcon;
                break;
            default:
                //instagram
                socialIcon = instagramIcon;
        }

        let leftSliderRender = <></>;
        let rightSliderRender = <></>;
        if (teachers.length > 4) {
            leftSliderRender = <div className='slider-column' onClick={event => sliderEvent(true)}>
                <div className='slider'>
                    <img className='img-slider-style' src={sliderIcon}></img>
                </div>
            </div>
            rightSliderRender = <div className='slider-column' onClick={event => sliderEvent(false)}>
                <div className='slider'>
                    <img className='img-slider-style r180' src={sliderIcon} ></img>
                </div>
            </div>;
        }

        return (
            <div className='teacher-container'>
                <div className='row teacher-description-container'>
                    {/* Teachers images */}
                    <div className='col-7'>
                        <div className='row'>
                            {leftSliderRender}
                            {teachers.filter(s => s.Id !== activeTeacher.Id).slice(teachers.length - 4).map((teacher, i) => {
                                if (i < 3) {
                                    return (
                                        <div className='column'>
                                            <img className='img-teacher-style' src={teacher.Presentation_Cover_Url} onClick={event => changeSelectedTeacher(teacher.Id)}></img>
                                        </div>
                                    );
                                }

                            })}
                            <div className='column column-selected' ref={teacherImageLarge}>
                                <img className='img-teacher-style-selected' src={activeTeacher.Presentation_Cover_Url} onClick={event => changeSelectedTeacher(activeTeacher.Id)}></img>
                            </div>
                            {rightSliderRender}
                        </div>
                    </div>
                    {/* Teacher information */}
                    <div className="col-5 teacher-description">
                        <div className='teacher-text' ref={teacherInformation}>
                            <p className='font-mafisha teacher-description-header'>{activeTeacher.First_Name + " " + activeTeacher.Last_Name}</p>
                            <p className="font-josefin">
                                {activeTeacher.Presentation}
                            </p>
                            <a href={activeTeacher.Social_Url} target="_blank">
                                <img className='icon-style' src={socialIcon} />
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }


}