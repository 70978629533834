import './Home_Reviews.css';
import ImageContent_1 from './../../../Media/DSC01146.jpg';
import ImageContent_2 from './../../../Media/DSC01284.jpg';
import ImageContent_3 from './../../../Media/DSC01289.jpg';
import ImageContent_Face_1 from './../../../Media/perfil_face.jpg';
import sliderImage from './../../../Media/slider.png';
import quoteImage from './../../../Media/quotes.png';

export default function Home_Reviews(props) {
    const slides = [
        { image: ImageContent_1, image_face: ImageContent_Face_1, identifier: 'c1', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis luctus libero. " },
        { image: ImageContent_2, image_face: ImageContent_Face_1, identifier: 'c2', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer congue nisi nec." },
        { image: ImageContent_3, image_face: ImageContent_Face_1, identifier: 'c3', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis enim et. " },
        { image: ImageContent_1, image_face: ImageContent_Face_1, identifier: 'c4', title: "Laura Benavidez", description: "TLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis risus dapibus. " },
        { image: ImageContent_2, image_face: ImageContent_Face_1, identifier: 'c5', title: "Laura Benavidez", description: "Hacer deporte con Caro ha sido maravilloso, me siento cada vez mejor y mas energica." },
        { image: ImageContent_3, image_face: ImageContent_Face_1, identifier: 'c6', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie lacinia velit. " },
        { image: ImageContent_1, image_face: ImageContent_Face_1, identifier: 'c7', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ac diam vel. " },
        { image: ImageContent_2, image_face: ImageContent_Face_1, identifier: 'c8', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis risus dapibus. " },
        { image: ImageContent_3, image_face: ImageContent_Face_1, identifier: 'c9', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis enim et. " },
        { image: ImageContent_1, image_face: ImageContent_Face_1, identifier: 'c10', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis luctus libero. " },
        { image: ImageContent_2, image_face: ImageContent_Face_1, identifier: 'c11', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie lacinia velit. " },
        { image: ImageContent_3, image_face: ImageContent_Face_1, identifier: 'c12', title: "Laura Benavidez", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer congue nisi nec." },
    ]
    let ScrollValue = 0;
    let FocusedReviewIsLast = false;
    /* Carrousel Logic */
    const slideLeft = () => {
        var slider = document.getElementById("slider-container");
        slider.scrollLeft = slider.scrollLeft + 200;
        hideAllCardContent('');
    }

    const slideRight = () => {
        var slider = document.getElementById("slider-container");
        slider.scrollLeft = slider.scrollLeft - 200;
        hideAllCardContent('');
    }

    const slideMaxLength = () => {
        var slider = document.getElementById("slider-container");
        slider.scrollLeft = slider.scrollLeftMax;
    }

    const hideAllCardContent = (identifier) => {
        //remove focus for any other card
        var allCards = document.getElementsByClassName('slider-card-focused');
        Array.prototype.forEach.call(allCards, function (card) {
            card.classList.remove('slider-card-focused');
        });

        //hide any content being shown.
        var allCardsContent = document.getElementsByClassName('slider-card-content');
        var filteredCards = [...allCardsContent].filter(card => card.id !== ('card-content-' + identifier));

        Array.prototype.forEach.call(filteredCards, function (card) {
            card.style.setProperty('opacity', 0);
        });
    }

    const showCardContent = (identifier) => {
        hideAllCardContent(identifier);
        var cardContent = document.getElementById('card-content-' + identifier);
        cardContent.style.setProperty('opacity', '1');
        var card = document.getElementById('card-' + identifier);
        if (card) {
            card.classList.add('slider-card-focused');
        }
        FocusedReviewIsLast = slides.findIndex(s =>s.identifier == identifier) == slides.length-1;
        if (FocusedReviewIsLast){
            slideMaxLength();
        }
    }

    const scrollChanged = (e) => {
        const { scrollLeft, clientWidth } = e.target;
        const _scrollDelta = Math.abs(ScrollValue - scrollLeft);
        ScrollValue = scrollLeft; 
        //console.log(e);
        if (_scrollDelta >= 50 && _scrollDelta <= 200 && !FocusedReviewIsLast){
            hideAllCardContent('');
        }
    }

    return (
        
            <div className='review-container2' >
                <div className='main-slider-container2'>
                    <img src={sliderImage} className='slider-icon left' aria-hidden="true" onClick={() => slideRight()}></img>
                    <div className='slider-container2' id="slider-container" onScroll={scrollChanged}>
                        {slides.map((slide, index) => {
                            return (
                                <div className='slider-card2' key={index} onClick={() => showCardContent(slide.identifier)} id={'card-' + slide.identifier}>
                                    <div id={'card-image-' + slide.identifier} className='slider-card-image' style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover' }}>

                                        <div className='slider-card-content' id={'card-content-' + slide.identifier} >
                                            <img src={quoteImage} className="card-content-quote" />
                                            <p className="card-content-quote-description" >{slide.description}</p>
                                            <div>
                                                <img src={slide.image_face} className="card-content-face"></img>
                                                <span className="card-content-name">
                                                    {slide.title}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <img src={sliderImage} className='slider-icon right' aria-hidden="true" onClick={() => slideLeft()}></img>

                </div>
            </div>
            
    )
}