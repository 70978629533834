import './Home_Plans.css';

export default function Home_Plans(props) {

    return (
        <div className='plans-main-container'>
            <div className='plans-title font-mafisha'>
                <label>Estás en el lugar ideal</label>
            </div>

            <div className='plans-content font-josefin'>
                <p>
                    Imagina un camino donde la fuerza interior se convierte en el cimiento sobre el cual construimos nuestras
                    metas y sueños. FIBSTUDIO no es solo un programa de desafíos físicos, sino un viaje de transformación
                    profundo que nos invita a explorar los rincones más íntimos de nuestro ser.
                </p>
            
            </div>

            <table className='table-center'>
                <tr>
                    <td>
                        <button className="btn-general font-josefin">
                            <label>MENSUAL
                                <br></br>
                                $35 USD</label>
                        </button>
                    </td>

                    <td>
                        <button className="btn-general font-josefin">
                            <label>TRIMESTRAL
                                <br></br>
                                $94 USD</label>
                        </button>
                    </td>

                    <td>
                        <button className="btn-general font-josefin">
                            <label>ANUAL
                                <br></br>
                                $334 USD</label>
                        </button>
                    </td>
                </tr>
            </table>
            

        </div>
    );
}